#h-objectives {
    background-color: #f1f1f1;
}

#h-objectives .title-page {
    padding-top: 80px;
    padding-bottom: 30px;
}

#h-objectives .title-page {
    max-width: 100% !important;
    margin-bottom: 40px;
}

#h-objectives img {
    width: 80%;
}

#h-objectives .row {
    margin-top: 40px;
}

#h-objectives li {
    list-style: none;
    padding-top: 5px;
    padding-bottom: 5px;
}

#h-objectives li p,
#h-objectives li span {
    display: inline-block;
    vertical-align: top;
}

#h-objectives li span {
    color: #3b4850;
    width: 15px;
    border-radius: 30px;
    background-color: #d79a35;
    height: 15px;
    text-align: center;
    padding: 1px;
    font-weight: bold;
    margin-right: 10px;
}

#h-objectives li p {
    color: #3b4850;
    max-width: 90%;
}