.btn-file {
    position: relative;
    overflow: hidden;
    width: 200px;
    height: 40px;
    background-color: #377dff;
    border-color: #377dff;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}