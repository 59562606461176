#h-profile {
    padding-top: 80px;
    padding-bottom: 80px;
    min-height: 78.3vh;
    background-color: white;
}

#h-profile .nav-item {
    background-color: white;
}

#h-profile .nav-item {
    cursor: pointer;
    color: #d79a35;
    background-color: white;
}

#h-profile .nav-item a {
    color: #d79a35;
}

#h-profile .nav-pills .nav-link.active,
#h-profile .nav-pills .show>.nav-link {
    color: white !important;
    background-color: #d79a35;
}

#h-profile .title-page {
    margin-bottom: 20px;
    margin-top: 20px;
}

#h-profile form {
    max-width: 600px;
    margin: 0 auto;
}

#h-profile form .form-control {
    padding: 12px;
}

#h-profile form button {
    display: block;
    margin: 0 auto;
    margin-top: 30px !important;
}