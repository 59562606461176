#h-contact {
    min-height: 71vh;
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #091636;
}

#h-contact .form-label {
    color: white;
}

#h-contact .contac-strong {
    color: white;
    display: block
}

#h-contact p {
    font-size: 18px;
    max-width: 300px;
    color: white;
}

#h-contact .title-page {
    margin-bottom: 80px;
}

#h-contact .social-media {
    margin-left: -15px;
    margin-top: 10px;
}

#h-contact .social-media li {
    display: inline-block;
    list-style: none;
    color: #000;
    width: 40px;
    font-size: 25px;
    margin-right: 40px;
    border-radius: 5px;
    text-align: center;
    margin-left: -15px;
    background-color: #d69a35;
}

#h-contact .social-media li a { 
    color: white;
}