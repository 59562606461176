#m-top {
    padding-top: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: -130px;
    background-image: url('../../../public/assets/fondo-01.png');
}

#m-top .container .title-page {
    font-size: 60px;
    max-width: 700px;
    margin-top: 100px;
    text-shadow: 2px 2px #333;
}

#m-top .container .decription {
    color: white;
    font-size: 23px;
    max-width: 720px;
    margin: 0 auto;
    margin-top: 40px;
    text-shadow: 2px 2px #333;
}

#m-why-member {
    padding-top: 150px;
    padding-bottom: 40px;
}

#m-why-member .row {
    margin-bottom: 70px;
}

#m-why-member li {
    list-style: none;
    padding-top: 5px;
    padding-bottom: 5px;
}

#m-why-member li p,
#m-why-member li span {
    color: white;
    display: inline-block;
    vertical-align: top;
}

#m-why-member li span {
    width: 25px;
    border-radius: 30px;
    background-color: #d79a35;
    height: 25px;
    text-align: center;
    padding: 1px;
    font-weight: bold;
    margin-right: 10px;
}

#m-why-member p {
    color: #333333;
    max-width: 500px;
}

#m-member {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #f1f1f1;
}

#m-member .title-page {
    color: #d79a35;
    font-weight: bold;
    max-width: 380px;
    line-height: 40px;
}

#m-member p.decription {
    max-width: 500px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

#m-member ul li {
    color: #333333;
    font-size: 18px;
    max-width: 450px;
    list-style: none;
    margin-left: -32px;
    margin-bottom: 40px;
}

#m-member-requirements {
    padding-top: 80px;
    padding-bottom: 40px;
    background-color: white;
}

#m-member-requirements .title-page {
    text-align: left;
    margin-bottom: 30px;
}

#m-member-requirements .title-page:nth-child(3) {
    margin-top: 80px;
}

#m-member-requirements li {
    list-style: none;
    padding-top: 5px;
    margin-left: -30px;
    padding-bottom: 5px;
}

#m-member-requirements li p,
#m-member-requirements li span {
    display: inline-block;
    vertical-align: top;
}

#m-member-requirements li span {
    color: #3b4850;
    width: 15px;
    border-radius: 30px;
    background-color: #d79a35;
    height: 15px;
    text-align: center;
    padding: 1px;
    font-weight: bold;
    margin-right: 10px;
}

#m-member-requirements li p {
    color: #3b4850;
    max-width: 90%;
}

#m-member-requirements .row {
    margin-bottom: 50px;
}

#m-member-requirements .row:last-child p {
    max-width: 460px;
}

#m-member-footer {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #091636;
}

#m-member-footer p {
    font-size: 18px;
    color: white;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 20px;
}

#m-member-footer .container {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../public/assets/line.webp');
}