#header-panel {
    padding: 5px !important;
    background-color: #d3d3d3 !important;
}

.sign-off {
    padding: 10px;
    cursor: pointer;
}

.sign-off:hover {
    color: #d79a35;
}