#header-page {
    padding: 10px;
    background-color: #d3d3d3;
}

#header-page .options {
    margin-top: 15px;
    text-align: right;
}

#header-page .options a {
    color: #333;
}

#header-page span {
    display: inline-block;
    vertical-align: middle;
}

#header-page a.button {
    padding: 3px;
    cursor: pointer;
    color: white;
    margin-right: 20px;
    padding: 10px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 30px;
    text-decoration: none;
    background-color: #d79a35;
}

#header-page span.nav {
    cursor: pointer;
    display: inline-block;
    color: #d69a35;
    font-size: 28px;
    margin-left: 20px;
}

#header-page .menu {
    padding: 10px;
    z-index: 999;
    width: 300px;
    right: 390px;
    margin-top: -5px;
    display: none;
    border-radius: 10px;
    position: fixed;
    right: 0%;
    background-color: white;
    border: solid 1px gainsboro;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    #header-page .menu {
        right: 5% !important; 
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 2000px) {
    #header-page .menu {
        right: 20% !important; 
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 3000px) {
    #header-page .menu {
        right: 25% !important; 
    }
}

#header-page .nav:hover > .menu {
    display: block;
}

#header-page .menu ul {
    margin-bottom: 0px;
}

#header-page .menu li {
    list-style: none;
    margin-left: -30px;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: dashed 1px #d79a35;
}

#header-page .menu li:last-child {
    border-bottom: none;
    padding-bottom: 0px;
}

#header-page .menu li a{ 
    color: #333333;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
}

#header-page .menu li a:hover{ 
    color: #d69a35;
}

#header-page .bar-auth {
    margin-left: 5px;
    margin-right: 5px;
    color: #aeaeae
}

#header-page .option-auth {
    cursor: pointer;
    text-decoration: none;
}

#header-page .option-auth:hover {
    color: #d69a35;
}

#header-page .bar-auth {
    margin-left: 5px;
    margin-right: 5px;
}

#social-media-header {
    margin-top: 15px;
}

#social-media-header li{
    display: inline-block;
    list-style: none;
    color: white;
    width: 40px;
    font-size: 25px;
    margin-right: 30px;
    border-radius: 5px;
    text-align: center;
    margin-left: -15px;
    background-color: #d69a35;
}

#social-media-header li a { 
    color: white; 
}

#social-media-header li:last-childd {
    margin-right: 0px;
}