#generate-code {
    text-align: center;
    width: 500px;
    margin: 0 auto;
    display: table;
    min-height: 71vh;
    padding: 30px;
}

#generate-code div {
    width: 450px;
    display: table-cell;
    vertical-align: middle;
    margin:  auto;
}

#generate-code div p {
    font-size: 18px;
}

#generate-code button {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    border-color: #d69a35;
    background-color: #d69a35;
}

#generate-code h5 {
    width: 100%;
    color: #d69a35;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: dashed 1px gainsboro;
}


#generate-code .link-movies{
    color: #fff;
    text-decoration: none;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    display: block;
    width: 200px;
    margin: 0 auto;
    margin-top: 30px !important;
    border-color: #d69a35;
    background-color: #d69a35;
}

#generate-code .link-movies:hover {
    background-color: #b5812b;
}