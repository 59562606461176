#h-top {
    height: 700px;
    margin-bottom: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../../public/assets/fondo-01.png');
}

#h-top div {
    max-width: 500px;
    padding-top: 150px;
    text-align: center;
    margin: 0 auto;
}

#h-top h1,
#h-top h3,
#h-top p span {
    color: #d79a35;
}

#h-top h1 {
    font-size: 80px;
}

#h-top h3 {
    width: 400px;
    font-weight: bold;
    font-size: 35px;
    margin: 0 auto;
    margin-bottom: 40px;
}

#h-top p {
    color: white;
    font-size: 20px;
}

#h-top .button {
    padding: 5px;
    cursor: pointer;
    color: white;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 30px;
    background-color: #d79a35;
}

#h-why-member p.decription {
    color: #3b4850;
    max-width: 450px;
    margin: 0 auto;
    text-align: center;
    margin-top: 40px;
}

#h-why-member .row {
    margin-top: 50px;
    margin-bottom: 70px;
}

#h-why-member li {
    list-style: none;
    padding-top: 5px;
    padding-bottom: 5px;
}

#h-why-member li p,
#h-why-member li span {
    display: inline-block;
    vertical-align: top;
}

#h-why-member li span {
    color: white;
    width: 25px;
    border-radius: 30px;
    background-color: #d79a35;
    height: 25px;
    text-align: center;
    padding: 1px;
    font-weight: bold;
    margin-right: 10px;
}

#h-why-member li p {
    color: #3b4850;
    max-width: 350px;
}

#h-ars {
    padding-top: 80px;
    padding-bottom: 80px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../../public/assets/fondo-01.png');
}

#h-ars .title-page {
    max-width: 400px;
}

#h-ars h3 {
    color: white;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

#h-ars p {
    color: white;
    text-align: center;
    margin-bottom: 0px;
}

#h-ars .row {
    margin-bottom: 40px;
}

#h-ars .row img {
    width: 100px;
}

#h-ars .row p {
    max-width: 280px;
    margin: 0 auto;
}

#h-ars .button {
    font-size: 18px;
    display: block;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 70px;
}

#h-vote {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 80px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../../public/assets/fondo-01.png');
}

#h-vote .title-page {
    margin-bottom: 20px;
}

#h-vote h4 {
    margin-bottom: 10px;
}

#h-vote p,
#h-vote h4 {
    color: white;
    text-align: center;
}

#h-vote .movies .movie {
    width: 400px;
    margin: 1px;
    display: inline-block;
    vertical-align: middle;
}

#h-vote .movies .movie img {
    width: 400px;
    height: 600px;
}

#h-vote .movies .movie .cover {
    width: 400px;
    height: 600px;
    display: none;
    position: absolute;
    background-color: rgba(214, 154, 53, 0.85);
}

#h-vote .movies .movie .cover .text {
    position: absolute;
    top: 0;
    padding: 20px;
    width: 100%;
}

#h-vote .movies .movie .cover .text p {
    text-align: left;
    margin: 0px;
}

#h-vote .movies .movie .cover .text label {
    color: white;
    display: block;
    font-size: 20px;
    text-align: center;
}

#h-vote .movies .movie .cover .text h3 {
    color: white;
    text-align: center;
    font-size: 40px;
    margin-top: 80px;
    margin-bottom: 40px;
}

#h-vote .movies .movie .cover .text p {
    font-size: 20px;
}

#h-vote .button {
    padding: 5px;
    cursor: pointer;
    color: white;
    font-size: 18px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 30px;
    background-color: #d79a35;
    display: block;
    max-width: 200px;
    margin: 0 auto;
    text-decoration: none;
    margin-top: 70px;
}

#h-vote .button:hover {
    background-color: #9b722e; 
}

#h-vote .movies .movie .cover .text .button {
    border: solid 2px white;
}

#h-vote .movies .movie:hover > .cover {
    display: block;
}