#n-top {
    padding-top: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: -130px;
    background-color: #091636;
    background-image: url('../../../public/assets/fondo-01.png');
}

#n-top .nav-bar a, #n-top .nav-bar span {
    text-shadow: 2px 2px #333; 
}

#n-top .container {
    text-align: center;
}

#n-top .container .title-page {
    font-size: 60px;
    max-width: 700px;
    margin-top: 100px;
    text-shadow: 2px 2px #333;
}

#n-top .container .decription {
    color: white;
    font-size: 23px;
    max-width: 350px;
    font-size: 25px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    text-shadow: 2px 2px #333;
}

#n-top .button {
    padding: 5px;
    cursor: pointer;
    color: white;
    font-size: 18px;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 30px;
    text-decoration: none;
    background-color: #d79a35;
}

#n-top .button:hover {
    background-color: #9b722e; 
}

#n-vote {
    padding-top: 80px;
    padding-bottom: 80px;
    /*background-color: #091636;
    background-repeat: no-repeat;
    background-image: url('../../../public/assets/fondo-01.png');*/
}

#n-vote .title-page {
    margin-bottom: 20px;
}

#n-vote h4 {
    margin-bottom: 10px;
}

#n-vote p,
#n-vote h4 {
    color: white;
    text-align: center;
}

#n-vote .movies .movie {
    width: 400px;
    margin: 1px;
    display: inline-block;
    vertical-align: middle;
}

#n-vote .movies .movie img {
    width: 400px;
    height: 600px;
}

#n-vote .movies .movie .cover {
    width: 400px;
    height: 600px;
    display: none;
    position: absolute;
    background-color: rgba(214, 154, 53, 0.85);
}

#n-vote .movies .movie .cover .text {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 20px;
}

#n-vote .movies .movie .cover .text p {
    text-align: left;
    margin: 0px;
}

#n-vote .movies .movie .cover .text label {
    color: white;
    display: block;
    font-size: 20px;
    text-align: center;
}

#n-vote .movies .movie .cover .text h3 {
    color: white;
    text-align: center;
    font-size: 30px;
    margin-top: 80px;
    margin-bottom: 40px;
}

#n-vote .movies .movie .cover .text p {
    font-size: 20px;
}

#n-vote .button {
    padding: 5px;
    cursor: pointer;
    color: white;
    font-size: 18px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 30px;
    background-color: #d79a35;
    display: block;
    max-width: 200px;
    margin: 0 auto;
    margin-top: 70px;
}

#n-vote .button:hover {
    background-color: #9b722e; 
}

#n-vote .movies .movie .cover .text .button {
    padding: 5px;
    cursor: pointer;
    color: white;
    text-align: center;
    font-size: 20px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 30px;
    background-color: #d79a35;
    display: block;
    font-weight: bold;
    max-width: 170px;
    margin: 0 auto;
    text-decoration: none;
    margin-top: 70px;
    border: solid 2px white;
}

#n-vote .movies .movie .cover .text .button:hover {
    background-color: #9b722e; 
}

#n-vote .movies .movie:hover >.cover {
    display: block;
}