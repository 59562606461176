#sidebar {
    min-height: 87.5vh;
}

#sidebar i {
    margin-right: 10px;
}

#sidebar .nav .nav-link {
    color: #333;
    padding: 15px;
    font-size: 15px;
    border-bottom: solid 1px gainsboro;
}

#sidebar .nav .nav-link:hover {
    color: white;
    background-color: #d79a35;
}

#sidebar .nav .active  {
    color: white;
    background-color: #d79a35;
}