#h-board-directors {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: white;
}

#h-board-directors .title-page {
    margin-bottom: 80px;
}

#h-board-directors .row {
    margin-bottom: 30px !important;
}

#h-board-directors .row .col-md-4 {
    text-align: center;
}

#h-board-directors .row:last-child {
    margin-bottom: 0px !important;
}

#h-board-directors .row img {
    height: 220px;
    border-radius: 50%;
    border: solid 3px #d79a35;
}

#h-board-directors h3 {
    color: #d79a35;
    margin-top: 30px;
}

#h-board-directors label {
    color: #333;
    font-weight: bold;
}