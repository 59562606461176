#h-contact {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #091636;
}

#h-contact p {
    font-size: 18px;
    max-width: 300px;
    color: white;
}

#h-contact .title-page {
    margin-bottom: 80px;
}

.nav-bar {
    margin-left: -30px;
}

.nav-bar li {
    list-style: none;
}

.nav-bar a, .nav-bar span {
    color: white;
    font-size: 18px;
    text-decoration: none;
}

.nav-bar span {
    padding-left: 10px;
    padding-right: 10px;
}