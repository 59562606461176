#hi-health-insurance {
    padding-top: 80px;
    min-height: 80vh !important;
    background-color: #091636;
    background-image: url('../../../public/assets/fondo-01.png');
}

#hi-health-insurance h3 {
    color: white;
    font-size: 40px;
    font-weight: normal;
    text-shadow: 2px 2px #333;
}

#hi-health-insurance .title-page {
    max-width: 580px;
    text-align: left;
    margin: 0;
    font-size: 50px;
    text-shadow: 2px 2px #333;
    margin-bottom: 60px;
    font-weight: normal;
}

#hi-health-insurance .card-list {
    padding: 40px;
    background-color: white;
}

#hi-health-insurance .card-list ul {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0px;
    width: 278px;
}

#hi-health-insurance .card-list ul li {
    list-style: none;
    margin-left: -30px;
}

#hi-health-insurance .card-list ul li p,
#hi-health-insurance .card-list ul li span {
    color: #3b4850;
    display: inline-block;
    vertical-align: top;
}

#hi-health-insurance .card-list ul li span {
    color: white;
    width: 25px;
    border-radius: 30px;
    background-color: #d79a35;
    height: 25px;
    text-align: center;
    padding: 1px;
    font-weight: bold;
    margin-right: 10px;
}

#hi-health-insurance .card-list ul li p {
    width: 230px;
    margin-left: 5px;
}

#hi-health-insurance footer {
    margin-top: 100px;
}