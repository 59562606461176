#forget-password {
    text-align: center;
}

#forget-password .div-forget-password {
    width: 500px;
    margin: 0 auto;
    display: table;
    min-height: 76vh;
}

#forget-password form {
    width: 450px;
    display: table-cell;
    vertical-align: middle;
    margin:  auto;
    padding-top: 50px;
    padding-bottom: 50px;
}

#forget-password button {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    margin-bottom: 10px;
    border-color: #d69a35;
    background-color: #d69a35;
}

#forget-password label {
    width: 100%;
    text-align: left;
}

#forget-password h5 {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
}

#forget-password .forget-password {
    display: block;
    cursor: pointer;
    color: #d69a35;
    text-align: center;
    text-decoration: none;
    margin-top: 10px;
    margin-bottom: 10px;
}

#forget-password .forget-password:hover {
    color: #b5812b;
}