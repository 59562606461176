#bd-player {
    min-height: 55.7vh;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: white;
    background-color: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(9, 22, 54, 0));

}

#bd-player .video-wrapper {
    margin: 10px;
    border-bottom: dashed 1px gainsboro;
}

#bd-player .video-wrapper h5 {
    margin-top: 15px;
}

#bd-player .search {
    margin-top: 10px;
}

#bd-player .search .input-group {
    max-width: 800px;
}

#bd-player .search .input-group-text {
    background-color: white;
    border-color: #e7eaf3;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

#bd-player .search .form-control {
    border-left: 0px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

#bd-player .search .form-control:focus, #bd-player .search .form-control:hover {
    outline: none !important;
    box-shadow: none;
    border-color: #e7eaf3;
}

#bd-player .video {
    cursor: pointer;
    padding: 10px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: white;
}

#bd-player .video:hover {
    background-color: #d79a35;
}

#bd-player .video:hover>label {
    color: white;
}

#bd-player .video img {
    width: 100%;
    height: 250px !important;
    border: solid 1px #333;
    border-radius: 3px;
}

#bd-player .video label {
    color: #333;
    margin-top: 10px;
}

#bd-player .video span {
    color: white;
}

#md-check-code label {
    display: block;
    text-align: center;
    font-size: 18px !important;
    margin-top: 10px;
}

#md-check-code input {
    font-size: 18px !important;
    max-width: 250px !important;
    text-align: center !important;
    margin: 0 auto !important;
}

#md-check-code .modal-footer button {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    border-color: #d69a35;
    background-color: #d69a35;
}