#footer-panel {
    width: 100%;
    margin: 0px;
    border-top: solid 1px gainsboro;
}

#footer-panel .copyright {
    text-align: center;
    padding: 10px;
    margin: 0px;
}