body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-ui h3 {
  font-size: 18px !important;
}

#loading {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  align-items: center;
  flex: 1;
  justify-content: center;
}

#loading .background {
  width: 100%;
  height: 100vh;
  background-color: #333;
  opacity: 0.7;
}

#loading i {
  color: white
}

#loading .text {
  font-size: 20px;
  color: white;
  padding: 10;
  display: block;
  margin-top: 20px;
  padding-top: 20;
}

#loading .container {
  text-align: center;
  position: absolute;
}

.title-page {
  text-align: center;
  color: #d79a35;
  margin: 0 auto;
  font-size: 35px;
  font-weight: bold;
  word-wrap: anywhere !important;
}

.no-data {
  display: block;
}

.card-header {
  background-color: transparent !important;
}

.search-card {
  max-width: 300px;
  border-bottom: solid 1px gainsboro;
}

.search-card i {
  color: #6c757d !important;
}

.search-card .input-group-text {
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 14px !important;
  border: none !important;
  background-color: transparent !important;
}

.search-card .datatableSearch {
  border: none !important;
  padding-bottom: 0px;
  background-color: transparent !important;
}

.modal-header .modal-title {
  font-size: 16px !important;
}

.modal-header .btn-close {
  font-size: 13px !important;
}

.modal-body .form-label {
  font-size: 15px !important;
}

.form-control {
  font-size: 14px !important;
  padding: 13px;
  border: .0625rem solid #e7eaf3;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #677788;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: .0625rem solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: .54688rem .875rem;
  font-size: .875rem;
  line-height: 1.6;
  border-radius: .3125rem;
  transition: all .2s ease-in-out;
}

.table-responsive .btn {
  padding: .2rem .54rem !important;
}

.table-responsive .sc-hKMtZM span {
  font-size: 14px !important;
}

.table-responsive .sc-evZas {
  font-size: 14px !important;
}

.btn-success,
.btn-success:disabled {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.btn-secondary {
  color: #fff;
  background-color: #71869d;
  border-color: #71869d;
}

.btn-primary {
  background-color: #0C9FE8;
  border: solid 1px #0C9FE8;
  color: white;
}

.btn:hover {
  color: #677788;
  text-decoration: none
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(55, 125, 255, .25)
}

.btn.disabled,
.btn:disabled {
  opacity: .65
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none
}

.btn-primary {
  color: #fff;
  background-color: #377dff;
  border-color: #377dff
}

.btn-primary:hover {
  color: #fff;
  background-color: #1164ff;
  border-color: #045cff
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #1164ff;
  border-color: #045cff;
  box-shadow: 0 0 0 .2rem rgba(85, 145, 255, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #377dff;
  border-color: #377dff
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #045cff;
  border-color: #0056f6
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(85, 145, 255, .5)
}

.btn-secondary {
  color: #fff;
  background-color: #71869d;
  border-color: #71869d
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5f7389;
  border-color: #596d82
}

.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: #5f7389;
  border-color: #596d82;
  box-shadow: 0 0 0 .2rem rgba(134, 152, 172, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #71869d;
  border-color: #71869d
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #596d82;
  border-color: #54667a
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(134, 152, 172, .5)
}

.btn-success {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7
}

.btn-success:hover {
  color: #fff;
  background-color: #00a387;
  border-color: #00967d
}

.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #00a387;
  border-color: #00967d;
  box-shadow: 0 0 0 .2rem rgba(38, 209, 180, .5)
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #00967d;
  border-color: #008972
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(38, 209, 180, .5)
}

.btn-info {
  color: #fff;
  background-color: #00c9db;
  border-color: #00c9db
}

.btn-info:hover {
  color: #fff;
  background-color: #00a6b5;
  border-color: #009aa8
}

.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #00a6b5;
  border-color: #009aa8;
  box-shadow: 0 0 0 .2rem rgba(38, 209, 224, .5)
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #00c9db;
  border-color: #00c9db
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #009aa8;
  border-color: #008e9b
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(38, 209, 224, .5)
}

.btn-warning {
  color: #1e2022;
  background-color: #f5ca99;
  border-color: #f5ca99
}

.btn-warning:hover {
  color: #1e2022;
  background-color: #f2b876;
  border-color: #f0b26b
}

.btn-warning.focus,
.btn-warning:focus {
  color: #1e2022;
  background-color: #f2b876;
  border-color: #f0b26b;
  box-shadow: 0 0 0 .2rem rgba(213, 177, 135, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #1e2022;
  background-color: #f5ca99;
  border-color: #f5ca99
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
  color: #1e2022;
  background-color: #f0b26b;
  border-color: #efac5f
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(213, 177, 135, .5)
}

.btn-danger {
  color: #fff;
  background-color: #ed4c78;
  border-color: #ed4c78
}

.btn-danger:hover {
  color: #fff;
  background-color: #ea295e;
  border-color: #e81e55
}

.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #ea295e;
  border-color: #e81e55;
  box-shadow: 0 0 0 .2rem rgba(240, 103, 140, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #ed4c78;
  border-color: #ed4c78
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #e81e55;
  border-color: #e2174e
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(240, 103, 140, .5)
}

.css-1s2u09g-control,
.css-1pahdxg-control {
  font-size: 15px;
  padding: 5px;
  border-color: rgb(231, 234, 243) !important;
}

.react-confirm-alert-overlay {
  z-index: 9999 !important;
}

.btn-adocine {
  cursor: pointer;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  border-color: #d69a35;
  background-color: #d69a35;
}

.btn-adocine:hover,
.btn-adocine:active,
.btn-adocine:not(:disabled):not(.disabled).active,
.btn-adocine:not(:disabled):not(.disabled):active,
.btn-adocine:not(:disabled):not(.disabled).active:focus,
.btn-adocine:not(:disabled):not(.disabled):active:focus,
.btn-adocine.focus,
.btn-adocine:focus {
  color: white;
  border-color: #b17e2b;
  background-color: #b17e2b;
}

.react-confirm-alert-svg {
  display: none;
}

.form-control:focus, .form-control:hover {
  outline: none !important;
  box-shadow: none;
  border: solid 1px #d79a35 !important
}