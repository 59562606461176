#vt-top {
    padding-top: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: -130px;
    margin-bottom: -40px;
    background-image: url('../../../public/assets/concert.webp');
    background-color: #000;
}

#vt-top .nav-bar a,
#vt-top .nav-bar span {
    text-shadow: 2px 2px #333;
}

#vt-top .container .title-page {
    font-size: 60px;
    margin-top: 100px;
    text-shadow: 2px 2px #333;
}

#vt-top .container .decription {
    color: white;
    text-align: center;
    font-size: 23px;
    max-width: 800px;
    font-size: 25px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    text-shadow: 2px 2px #333;
}

#vt-top span.button {
    padding: 5px;
    cursor: pointer;
    color: white;
    font-size: 18px;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 30px;
    background-color: #d79a35;
}

#vt-nominations {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #091636;
}

#vt-nominations .years {
    text-align: center;
}

#vt-nominations .years h5 {
    color: white;
    text-align: center;
}

#vt-nominations .years ul {
    margin-left: -30px;
}

#vt-nominations .years li {
    display: inline-block;
    color: white;
    list-style: none;
    padding: 10px;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #d79a35;
}

#vt-nominations .years li:hover {
    background-color: #b7822b;
}

#vt-nominations .years li.active {
    background-color: #b7822b;
}

#vt-nominations .years li:first-child {
    margin-right: 10px;
}

#vt-nominations .container .card-nomination {
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 10px;
    height: 120px;
    background-color: white;
    display: flex;
    cursor: pointer;
    align-items: center
}

#vt-nominations .container .card-nomination:hover {
    background-color: #d79a35;
}

#vt-nominations .container .card-nomination:hover>h2 {
    color: white;
}

#vt-nominations .container .card-nomination .title-page {
    font-size: 25px;
}

#md-nomination ul li {
    list-style: none;
    margin-left: -30px;
    margin-bottom: 20px;
    border-bottom: dashed 1px #d79a35;
}

#md-nomination ul li:last-child {
    border-bottom: none;
    margin-bottom: 0px;
}

#md-nomination ul li p {
    width: 92%;
    padding-right: 5px;
}

#md-nomination ul li p strong,
#md-nomination ul li p label {
    display: block;
    font-size: 18px;
}

#md-nomination ul li p,
#md-nomination ul li .checkbox-wrapper {
    display: inline-block;
    vertical-align: middle;
}

#md-nomination .checkbox-wrapper input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 1.6em;
    height: 1.6em;
    border-radius: 0.85em;
    margin-right: 0.5em;
    border: 0.15em solid #bd8730;
    outline: none;
    cursor: pointer;
}

#md-nomination .checkbox-wrapper input[type="checkbox"]:checked {
    background-color: #c48b2f;
}

#md-nomination .checkbox-wrapper input[type="checkbox"]:checked:disabled {
    background-color: #c0c0c0;
    border-color: #c0c0c0;
}

#md-nomination .checkbox-wrapper input[type="checkbox"]:disabled {
    border-color: #c0c0c0;
    cursor: not-allowed;
}

#md-nomination .checkbox-wrapper input[type="checkbox"]:disabled+span {
    color: #c0c0c0;
}

#md-nomination .checkbox-wrapper input[type="checkbox"]:focus {
    box-shadow: 0 0 20px #d79a35;
}

#md-nomination input.checked::before {
    content: "2714";
    font-size: 1.5em;
    color: #fff;
    position: absolute;
    right: 1px;
    top: -5px;
}

#md-nomination .options-check {
    font-size: 18px !important;
    text-align: center;
    background-color: gainsboro;
    color: #333;
    font-weight: bold;
    border: dashed 2px #d79a35;
    padding: 5px;
    border-radius: 10px;
}

#md-nomination button {
    cursor: pointer;
    color: white;
    min-width: 100px;
    padding: 10px;
    font-size: 15px;
    border-radius: 10px;
}

#md-nomination button:last-child {
    border-color: #d79a35;
    background-color: #d79a35;
}

#md-nomination .modal-title {
    color: #d79a35;
    font-size: 25px !important;
    width: 100% !important;
    text-align: center !important;
}

#md-nomination .modal-footer {
    justify-content: space-between !important;
}

#vt-nominations .container .card-nomination button:hover {
    border-color: #b07b24;
    background-color: #b07b24;
}

#vt-nominations .container .closed-voting {
    padding: 30px;
    font-size: 20px;
    text-align: center;
    max-width: 650px;
    margin: 0 auto;
    margin-top: 60px;
    background-color: white;
}

#vt-nominations .container .closed-voting p {
    margin: 0px;
    font-size: 25px;
    color: #333;
}